import { ImageRoutes } from 'core/constants/image-routes';
import { EMenuItemType } from 'core/model/enums/menu-links.enum';
import { IFooterColumn, IMenuLink, IStaticMenuEntry } from 'core/model/interfaces/menu.interface';
import { defaultRule } from 'shared/components/main-components/main-menu/main-menu.utils';
import { Path } from './path.constants';

export const SIDE_MENU_LINKS: Array<IMenuLink> = [
  {
    type: EMenuItemType.Link,
    label: 'Mis pedidos',
    icon: 'truck',
    path: Path.MY_ORDERS,
    rules: defaultRule,
  },
  { type: EMenuItemType.Divider },
  {
    type: EMenuItemType.Categories,
  },
  {
    type: EMenuItemType.Link,
    label: 'Capacitaciones',
    icon: 'education',
    path: Path.TRAINING,
    external: true,
  },
  {
    type: EMenuItemType.Link,
    label: 'Asesoría técnica',
    icon: 'assistant',
    path: Path.TECH_ASSISTANCE,
    external: true,
  },
];

export const BOTTOM_SIDE_MENU_LINKS: Array<IMenuLink> = [
  { type: EMenuItemType.Divider },
  {
    type: EMenuItemType.Link,
    label: 'Cerrar sesión',
    icon: 'sign-out',
    path: '/logout',
    rules: defaultRule,
  },
];

export const FOOTER_COLUMNS: Array<Array<IFooterColumn>> = [
  [
    { path: Path.MY_SESSION, label: 'Mi perfil' },
    { path: Path.MY_ORDERS, label: 'Mis pedidos' },
  ],
  [
    { path: Path.WHATSAPP, label: 'Atención al cliente', external: true },
    { path: Path.TECH_ASSISTANCE, label: 'Asesoría técnica', external: true },
    { path: Path.TRAINING, label: 'Capacitaciones', external: true },
  ],
];

export const STATIC_MENU_ENTRIES: Array<IStaticMenuEntry> = [
  {
    key: 'todos-entry',
    slug: null,
    path: Path.HOME,
    label: 'Todos',
    isHighlighted: false,
    icon: ImageRoutes.PLATFORM_DEFAULT,
  },
];
