import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { IDetailProduct, IPaginatedProducts, IProduct } from 'core/model/interfaces/product.interface';
import { IGetListProductsDTO, INavigationCatalogDTO, IProductDTO, IUnitMeasureDTO } from './catalogs.dto';

export const productDTOToIProduct = (product: IProductDTO): IProduct => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  // TODO: Considerar el caso cuando defaultUnitMeasure sea undefined
  const defaultUnitMeasure = unitMeasure.find(um => um.default === true) as IUnitMeasureDTO;

  return {
    image,
    brand,
    categoryId: Number(externalCategory.id),
    currency,
    name,
    commercialName: commercialName || name,
    eanCode,
    minimumSaleQuantity: defaultUnitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: defaultUnitMeasure.maximumSaleQuantity,
    presentation: defaultUnitMeasure.presentation,
    price: defaultUnitMeasure.price,
    originalPrice: defaultUnitMeasure.originalPrice,
    hasDiscount: defaultUnitMeasure.hasDiscount,
    sku,
    stock,
    subcategoryId: Number(externalFamily.id),
    sourceId,
    taxRate,
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    description,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    selectedUnitMeasure: {
      code: defaultUnitMeasure.code,
      default: defaultUnitMeasure.default,
      name: defaultUnitMeasure.description,
      presentation: defaultUnitMeasure.presentation,
      conversionFactor: defaultUnitMeasure.conversionFactor,
      price: defaultUnitMeasure.price,
      originalPrice: defaultUnitMeasure.originalPrice,
      minimumSaleQuantity: defaultUnitMeasure.minimumSaleQuantity,
      maximumSaleQuantity: defaultUnitMeasure.maximumSaleQuantity,
      hasDiscount: defaultUnitMeasure.hasDiscount,
    },
  };
};

export const navigationCatalogToCatalogueProducts = (
  catalog: INavigationCatalogDTO,
  treeItemType: 'subcategory' | 'category',
): ICatalogueProducts => ({
  [treeItemType]: {
    guid: catalog.treeItemId.toString(),
    name: catalog.description,
  },
  products: catalog.catalog.products.map(p => productDTOToIProduct(p)),
  pagination: catalog.catalog.pagination,
});

export const catalogDetailResponseToProduct = (product: IProductDTO): IDetailProduct => ({
  ...productDTOToIProduct(product),
  distributor: {
    id: product.externalDistributor.id,
    document: product.externalDistributor.document,
    name: product.externalDistributor.name,
  },
  unitMeasures: product.unitMeasure.map(uM => ({
    code: uM.code,
    default: uM.default,
    name: uM.description,
    presentation: uM.presentation,
    conversionFactor: uM.conversionFactor,
    price: uM.price,
    originalPrice: uM.originalPrice,
    minimumSaleQuantity: uM.minimumSaleQuantity,
    maximumSaleQuantity: uM.maximumSaleQuantity,
    hasDiscount: uM.hasDiscount,
  })),
});

export const listProductsToPaginatedProducts = ({ pagination, products }: IGetListProductsDTO): IPaginatedProducts => ({
  products: products.map(p => productDTOToIProduct(p)),
  pagination,
});
