import { IMAGES_ROUTES } from './general.constants';

export const ImageRoutes = {
  // CATALOGUE
  CATALOGUE_SEARCH_BOX: `${IMAGES_ROUTES}/statics/svgs/catalogues_search_box.svg`,
  // BENEFITS - BANNER
  BENEFITS_BANNER_MOBILE: `${IMAGES_ROUTES}/statics/images/benefits_banner_grocer.png`,
  BENEFITS_BANNER_DESKTOP: `${IMAGES_ROUTES}/statics/images/benefits_banner_desktop.png`,
  // BENEFITS - CARDS
  BENEFITS_COBRA_NIUBIZ: `${IMAGES_ROUTES}/statics/images/benefits_cobra_niubiz.png`,
  BENEFITS_INSUMA_EDUCA: `${IMAGES_ROUTES}/statics/images/benefits_insuma_educa.png`,
  BENEFITS_MANAGE_WILLY: `${IMAGES_ROUTES}/statics/images/benefits_manage_willy.png`,
  BENEFITS_TOUCH_LOAN: `${IMAGES_ROUTES}/statics/images/benefits_touch_loan.png`,
  BENEFITS_ICON_DOLLAR: `${IMAGES_ROUTES}/statics/images/benefits_icon_dollar.png`,
  // LOGO
  LOGO_MAIN_HEADER: `${IMAGES_ROUTES}/statics/svgs/logos_main_header.svg`,
  LOGO_FOOTER: `${IMAGES_ROUTES}/statics/svgs/logos_footer.svg`,
  // LOGIN
  LOGIN_BACKGROUND: `${IMAGES_ROUTES}/statics/images/login_background.png`,
  LOGIN_LOGO: `${IMAGES_ROUTES}/statics/svgs/logos_login.svg`,
  // PASSWORD - RECOVERY
  PR_USER_VALIDATION_BANNER: `${IMAGES_ROUTES}/statics/images/pr_user_validation_banner.png`,
  PR_USER_VALIDATION_BANNER_MOBILE: `${IMAGES_ROUTES}/statics/images/pr_user_validation_banner_mobile.png`,
  PR_CODE_VALIDATION_BANNER: `${IMAGES_ROUTES}/statics/images/pr_code_validation_banner.png`,
  PR_CODE_VALIDATION_BANNER_MOBILE: `${IMAGES_ROUTES}/statics/images/pr_code_validation_banner_mobile.png`,
  PR_NEW_PASSWORD_BANNER: `${IMAGES_ROUTES}/statics/images/pr_new_password_banner.png`,
  PR_NEW_PASSWORD_BANNER_MOBILE: `${IMAGES_ROUTES}/statics/images/pr_new_password_banner_mobile.png`,
  PR_NOT_FOUND_BANNER_MOBILE: `${IMAGES_ROUTES}/statics/images/pr_not_found_banner_mobile.png`,
  PR_NOT_FOUND_BANNER: `${IMAGES_ROUTES}/statics/images/pr_not_found_banner.png`,
  // EMOJIN
  EMOJIN_PARTY_POPPER: `${IMAGES_ROUTES}/statics/images/emojis/party_popper.png`,
  EMOJIN_SMILING_FACE: `${IMAGES_ROUTES}/statics/images/emojis/smiling_face.png`,
  EMOJIN_MOBILE_PHONE: `${IMAGES_ROUTES}/statics/images/emojis/mobile_phone.png`,
  EMOJIN_HUGGING_FACE: `${IMAGES_ROUTES}/statics/images/emojis/hugging_face.png`,
  EMOJIN_THUMBS_UP: `${IMAGES_ROUTES}/statics/images/emojis/thumbs_up.png`,
  EMOJIN_RAISING_HANDS: `${IMAGES_ROUTES}/statics/images/emojis/raising_hands.png`,
  EMOJIN_MILD_PANIC: `${IMAGES_ROUTES}/statics/images/emojis/mild_panic.png`,
  EMOJIN_FINGER_DOWN: `${IMAGES_ROUTES}/statics/images/emojis/finger_down.png`,
  EMOJIN_FINGER_RIGHT: `${IMAGES_ROUTES}/statics/images/emojis/finger_right.png`,
  EMOJIN_FINGER_UP: `${IMAGES_ROUTES}/statics/images/emojis/finger_up.png`,
  EMOJIN_MESSENGER: `${IMAGES_ROUTES}/statics/images/emojis/messenger.png`,
  EMOJIN_ALERT: `${IMAGES_ROUTES}/statics/images/emojis/alert.png`,
  EMOJIN_HANDSHAKE: `${IMAGES_ROUTES}/statics/images/emojis/handshake.png`,
  EMOJIN_NERVOUS: `${IMAGES_ROUTES}/statics/images/emojis/nervous.png`,
  EMOJIN_HANDS_UP: `${IMAGES_ROUTES}/statics/images/emojis/hands_up.png`,
  EMOJIN_GRIMACING_FACE: `${IMAGES_ROUTES}/statics/images/emojis/grimacing_face.png`,
  EMOJIN_WINKING_FACE: `${IMAGES_ROUTES}/statics/images/emojis/winking_face.png`,
  EMOJIN_MICROPHONE: `${IMAGES_ROUTES}/statics/images/emojis/microphone.png`,
  EMOJIN_NOODLES: `${IMAGES_ROUTES}/statics/images/emojis/noodles.png`,
  EMOJIN_CLOCK: `${IMAGES_ROUTES}/statics/images/emojis/clock.png`,
  EMOJIN_CELL_LEFT_ARROW: `${IMAGES_ROUTES}/statics/images/emojis/cell_left_arrow.png`,
  EMOJIN_DOLLAR_FACE: `${IMAGES_ROUTES}/statics/images/emojis/dollar_face.png`,
  EMOJIN_PRINTER: `${IMAGES_ROUTES}/statics/images/emojis/printer.png`,
  EMOJIN_SILVER_BAG: `${IMAGES_ROUTES}/statics/images/emojis/silver_bag.png`,
  EMOJIN_CARD: `${IMAGES_ROUTES}/statics/images/emojis/card.png`,
  EMOJIN_CART: `${IMAGES_ROUTES}/statics/images/emojis/cart.png`,
  EMOJIN_FIRE: `${IMAGES_ROUTES}/statics/images/emojis/fire.png`,
  ICON_YOUTUBE: `${IMAGES_ROUTES}/statics/svgs/icon_youtube.svg`,
  ICON_FACEBOOK: `${IMAGES_ROUTES}/statics/svgs/icon_facebook.svg`,
  ICON_INSTAGRAM: `${IMAGES_ROUTES}/statics/svgs/icon_instagram.svg`,
  // CHECKOUT SUCCESS
  CHECKOUT_PARTY_FACE: `${IMAGES_ROUTES}/statics/svgs/checkout_party_face.svg`,
  CHECKOUT_LOCATION: `${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_location.svg`,
  CHECKOUT_MONEY: `${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_money.svg`,
  CHECKOUT_CALENDAR: `${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_calendar.svg`,
  // PASSWORD RECOVERY
  PR_LOCK: `${IMAGES_ROUTES}/statics/images/pr_lock.png`,
  // OCTAGONS
  OCTAGONS_HIGH_FAT_TRANS: `${IMAGES_ROUTES}/statics/svgs/octagons/high_fat_trans.svg`,
  OCTAGONS_HIGH_FATS_SAT: `${IMAGES_ROUTES}/statics/svgs/octagons/high_fats_sat.svg`,
  OCTAGONS_SODIUM: `${IMAGES_ROUTES}/statics/svgs/octagons/high_sodium.svg`,
  OCTAGONS_SUGAR: `${IMAGES_ROUTES}/statics/svgs/octagons/high_sugar.svg`,
  // BANKS - QR
  BANK_BBVA: `${IMAGES_ROUTES}/statics/images/banks/bbva_logo.png`,
  BANK_BCP: `${IMAGES_ROUTES}/statics/images/banks/bcp_logo.png`,
  BANK_INTERBANK: `${IMAGES_ROUTES}/statics/images/banks/interbank_logo.png`,
  BANK_SCOTIABANK: `${IMAGES_ROUTES}/statics/images/banks/scotiabank_logo.png`,
  BANK_PLIN: `${IMAGES_ROUTES}/statics/images/banks/plin.png`,
  BANK_YAPE: `${IMAGES_ROUTES}/statics/images/banks/yape.png`,
  BANK_YAPE_LOGO: `${IMAGES_ROUTES}/statics/images/banks/yape_logo.png`,
  BANK_YAPE_QR: `${IMAGES_ROUTES}/statics/images/banks/qr/yape.png`,
  BANK_PLIN_QR: `${IMAGES_ROUTES}/statics/images/banks/qr/plin.png`,
  // PLATFORM_1
  PLATFORM_DEFAULT: `${IMAGES_ROUTES}/1_platform/icon/default-inactive.svg`,
  PLATFORM_PROMOTIONS: `${IMAGES_ROUTES}/1_platform/icon/bonificaciones-inactive.svg`,
  // SEARCH_PRODUCTS
  SEARCH_PRODUCTS_EMPTY_STATE: `${IMAGES_ROUTES}/statics/images/search_products/search_products_empty_state.png`,
  // PROFILE
  PROFILE_BANNER: `${IMAGES_ROUTES}/statics/images/profile/profile-banner.png`,
  // PAGE NOT FOUND
  PAGE_NOT_FOUND: `${IMAGES_ROUTES}/statics/images/page-not-found.png`,
};
