import { IOrderDisplay } from 'core/model/interfaces/order.interface';
import { ordersService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import type { IOrdersState } from '../types';

export const getOrderDetailAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getOrderDetail`,
  (orderId: IOrderDisplay['id']) => ordersService.getOrderDetail(orderId),
);

export const getOrderDetailReducer: TSliceExtraReducer<IOrdersState> = builder => {
  builder
    .addCase(getOrderDetailAction.pending, state => {
      state.loadingDetail = true;
    })
    .addCase(getOrderDetailAction.fulfilled, (state, { payload }) => {
      state.loadingDetail = false;
      state.errorDetail = null;
      state.orderDetail = payload;
    })
    .addCase(getOrderDetailAction.rejected, (state, { error }) => {
      state.loadingDetail = false;
      state.errorDetail = error;
    });

  return builder;
};
