export enum EOrderEvent {
  New = 'NEW',
  Request = 'REQUEST',
  ErrorNew = 'ERROR_NEW',
  Process = 'PROCESS',
  Annul = 'ANNUL',
  Confirm = 'CONFIRM',
  Refuse = 'REFUSE',
  Finalize = 'FINALIZE',
}

export enum EOrderCreatedStatus {
  Success = 'success',
  Rejected = 'rejected',
  Processing = 'processing',
}
