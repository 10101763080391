import { compose } from 'shared/utils/function-utils';
import { buildOptimisticCartReducer } from './build-optimistic-cart';
import { changeProductReducer } from './change-product-quantity';
import { changeProductReducerFromCart } from './change-product-quantity-from-cart';
import { getCartReducer } from './get-cart';
import { optimisticRemoveProductReducer } from './optimistic-remove-product';
import { removeProductReducer } from './remove-product';
import { removeProductFromCartReducer } from './remove-product-from-cart';

export const extraReducers = compose(
  optimisticRemoveProductReducer,
  buildOptimisticCartReducer,
  changeProductReducerFromCart,
  removeProductReducer,
  changeProductReducer,
  removeProductFromCartReducer,
  getCartReducer,
);

export { buildOptimisticCartAction } from './build-optimistic-cart';
export { changeProductAction } from './change-product-quantity';
export { changeProductActionFromCart } from './change-product-quantity-from-cart';
export { getCartAction } from './get-cart';
export { optimisticRemoveProductAction } from './optimistic-remove-product';
export { removeProductAction } from './remove-product';
export { removeProductFromCartAction } from './remove-product-from-cart';
