import { notification } from '@insuma/mpp-ui';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  AVOID_SHOWING_ERRORS_CODES,
  AVOID_SHOWING_ERRORS_ENDPOINTS,
  EHTTPErrorCode,
  UNEXPECTED_ERROR_MESSAGE,
} from 'core/constants/error-http.constants';
import { APPLICATION_ID } from 'core/constants/general.constants';
import { IServiceError } from 'core/model/interfaces/error.interface';
import { composePromises } from 'shared/utils/function-utils';
import msalPublicClientApplication from 'shared/utils/msal.utils';
import { acquireAccessToken } from 'shared/utils/token.utils';
import { generateTraceParent } from 'shared/utils/trace-parent.utils';

export const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };
  try {
    const token = await acquireAccessToken(msalPublicClientApplication);
    newConfig.headers.Authorization = `Bearer ${token}`;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Error acquiring a new token', e);
  }

  return newConfig;
};

export const injectApplicationId = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };
  newConfig.headers['Application-Id'] = APPLICATION_ID;
  return newConfig;
};

export const injectTraceParent = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };
  if (!window.newrelic) newConfig.headers.Traceparent = generateTraceParent();
  return newConfig;
};

export const injectHeaders = composePromises<InternalAxiosRequestConfig>(
  injectToken,
  injectApplicationId,
  injectTraceParent,
);
interface IShouldAvoidNotification {
  message: string;
  urlRequest: string | undefined;
  methodRequest: string | undefined;
  response: AxiosResponse | undefined;
}
const shouldAvoidNotification = ({ message, methodRequest, response, urlRequest }: IShouldAvoidNotification) =>
  response?.status === EHTTPErrorCode.UNAUTHORIZED ||
  AVOID_SHOWING_ERRORS_CODES.includes(response?.data?.code) ||
  message === 'canceled' ||
  AVOID_SHOWING_ERRORS_ENDPOINTS.some(endpointItem => {
    const isEndpointMatched = new RegExp(endpointItem.url).test(urlRequest ?? '');
    if (endpointItem.method) {
      return isEndpointMatched && endpointItem.method === methodRequest;
    }
    return isEndpointMatched;
  });

export const handleCommonErrors = (error: AxiosError<IServiceError>) => {
  const { response, message: messageAxios, config } = error;
  const shouldAvoidNotificationParams = {
    message: messageAxios,
    urlRequest: config?.url,
    methodRequest: config?.method,
    response,
  };
  if (shouldAvoidNotification(shouldAvoidNotificationParams)) return;
  const message = response?.data?.error?.message || UNEXPECTED_ERROR_MESSAGE;
  notification.error(message, {
    duration: 5000,
  });
};

export const getErrorFromService = (error: unknown, defaultErrorMessage?: string) => {
  let errorMessage = defaultErrorMessage || 'Error inesperado';

  if (axios.isAxiosError<IServiceError>(error)) {
    const serviceError = error.response?.data?.error;
    if (serviceError?.message) errorMessage = serviceError.message;
  }

  return errorMessage;
};
