import { Navigate, useLocation } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { notification } from '@insuma/mpp-ui';
import { DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS } from 'core/constants/msal.constants';
import { Path } from 'core/constants/path.constants';
import { Spinner } from 'shared/components/spinner/spinner';

import './index-page.scss';

export const IndexPage = () => {
  const { search } = useLocation();
  const isLoggedIn = useIsAuthenticated();
  const pathToRedirect = `${Path.HOME}${search}`;

  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isLoggedIn) {
    try {
      instance.loginRedirect({
        ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS,
        prompt: 'login',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('login error', error);
      notification.error(`Ocurrió un error inesperado durante el inicio de sesión`);
    }
  }

  if (isLoggedIn) {
    return <Navigate to={pathToRedirect} replace />;
  }

  return (
    <div className="index-page">
      <Spinner />
    </div>
  );
};
