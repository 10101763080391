import axios, { AxiosResponse } from 'axios';
import { API_RESPONSE_MS_TIMEOUT, API_URL, DEFAULT_API_RESPONSE_MS_TIMEOUT } from 'core/constants/general.constants';
import { isFill } from 'shared/utils/string.utils';
import createAuthenticatedResInterceptor from './http-auth-res-interceptors';
import { injectHeaders } from './http-utils';

const { CancelToken } = axios;

// eslint-disable-next-line import/no-mutable-exports
export let source = CancelToken.source();

export const generateNewCancelTokenSource = () => {
  source = axios.CancelToken.source();
};

export const https = axios.create({
  baseURL: API_URL,
  timeout: isFill(API_RESPONSE_MS_TIMEOUT) ? Number(API_RESPONSE_MS_TIMEOUT) : DEFAULT_API_RESPONSE_MS_TIMEOUT,
});

export const setToken = (token: string) => {
  https.defaults.headers.Authorization = `Bearer ${token}`;
};

const responseData = ({ data }: AxiosResponse): any => data;

https.interceptors.request.use(injectHeaders);

createAuthenticatedResInterceptor(https, responseData);
