import { createSelector } from '@reduxjs/toolkit';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import type { TRootState } from 'core/store/reducers';

export const authSelector = (state: TRootState) => state.auth;
export const authLoadingSelector = (state: TRootState) => state.auth.loading;
export const authGetCustomerSelector = (state: TRootState) => state.auth.customer;
export const authGetCustomerSource = (state: TRootState) => state.auth.customer?.source;
export const selectCustomerLegalConsent = (state: TRootState) => state.auth.customer.legalConsent;

export const authCustomerIsHybridSelector = createSelector(
  authGetCustomerSource,
  source => source === ECustomerSource.HYBRID,
);
