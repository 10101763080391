import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import {
  IGetAddressesDTO,
  IGetCreditLimitsDTO,
  IGetTypeDocumentsDTO,
  IGetTypeDocumentsRequestParams,
  IGetUserProfileResponse,
} from './customers.dto';

export const customersData = {
  getTypeDocuments: async ({ forUse }: IGetTypeDocumentsRequestParams): Promise<Array<IGetTypeDocumentsDTO>> => {
    const documents = await https.get<Array<IGetTypeDocumentsDTO>>(`/customers/v1/documents`, {
      params: {
        forUse,
      },
    });
    return Promise.resolve(documents);
  },

  getProfileUser: async (customerId: number): Promise<IGetUserProfileResponse> => {
    const { data } = await https.get<IServiceSuccess<IGetUserProfileResponse>>(
      `/gtm-user-management/v1/users/profile`,
      { headers: { 'customer-id': customerId } },
    );
    return data;
  },

  getAddresses: async (): Promise<IGetAddressesDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetAddressesDTO>>(`/gtm-user-management/v1/users/addresses`);
    return data;
  },

  getCreditLimits: async (customerId: number): Promise<IGetCreditLimitsDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetCreditLimitsDTO>>(
      `/gtm-user-management/v1/users/credit-limits`,
      { headers: { 'customer-id': customerId } },
    );
    return data;
  },
};
