import axios from 'axios';
import { NUMBER_OF_POST_ORDER_API_RETRIES, POST_ORDER_API_EXPONENTIAL_DELAY } from 'core/constants/checkout.constants';
import { CLIENT_OFFLINE_NETWORK_ERROR_CODE } from 'core/constants/error-http.constants';
import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { generateUUIDv4 } from 'shared/utils/uuid.utils';
import {
  ICreateOrderRequestParams,
  IGetOrderDetailDTO,
  IGetOrderParams,
  IGetOrdersDTO,
  IOrderCreatedResponseDTO,
} from './orders.dto';

const baseUrl = '/gtm-order-management/v1';

export const ordersData = {
  createOrder: async (
    body: ICreateOrderRequestParams,
    customerId: number,
    uuid?: string,
  ): Promise<IOrderCreatedResponseDTO> => {
    const idempotencyKey = uuid ?? generateUUIDv4();
    const { data } = await https.post<IServiceSuccess<IOrderCreatedResponseDTO>>(`${baseUrl}/orders`, body, {
      headers: {
        'Idempotency-Key': idempotencyKey,
        'customer-id': customerId,
      },
      'axios-retry': {
        retries: NUMBER_OF_POST_ORDER_API_RETRIES,
        retryDelay: count => count * POST_ORDER_API_EXPONENTIAL_DELAY,
        retryCondition: error => axios.isAxiosError(error) && error.code === CLIENT_OFFLINE_NETWORK_ERROR_CODE,
      },
    });
    return data;
  },

  getOrders: async (params: IGetOrderParams): Promise<IGetOrdersDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetOrdersDTO>>(`${baseUrl}/orders`, {
      params,
    });
    return data;
  },

  getOrderDetail: async (orderId: string): Promise<IGetOrderDetailDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetOrderDetailDTO>>(`${baseUrl}/orders/${orderId}`, {});

    return data;
  },
};
