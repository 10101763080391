import { Helmet } from 'react-helmet';

export const VWO = ({ accountId }: { accountId: string }) => (
  <Helmet>
    <script>
      {`window._vwo_code =
      window._vwo_code ||
      (function () {
        var account_id = ${accountId};
        var settings_tolerance = 2000;
        var library_tolerance = 2500;
        var use_existing_jquery = false;
        var is_spa = 1;
        var hide_element = 'body';
        var /* DO NOT EDIT BELOW THIS LINE */
          f = false,
          d = document;
        return {
          use_existing_jquery: function () {
            return use_existing_jquery;
          },
          library_tolerance: function () {
            return library_tolerance;
          },
          finish: function () {
            if (!f) {
              f = true;
              var a = d.getElementById('_vis_opt_path_hides');
              if (a) a.parentNode.removeChild(a);
            }
          },
          finished: function () {
            return f;
          },
          load: function (a) {
            var b = d.createElement('script');
            b.src = a;
            b.type = 'text/javascript';
            b.innerText;
            b.onerror = function () {
              _vwo_code.finish();
            };
            d.getElementsByTagName('head')[0].appendChild(b);
          },
          init: function () {
            settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
            var a = d.createElement('style'),
              b = 'body{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}';
            a.setAttribute('id', '_vis_opt_path_hides');
            a.setAttribute('type', 'text/css');
            if (a.styleSheet) a.styleSheet.cssText = b;
            else a.appendChild(d.createTextNode(b));
            d.getElementsByTagName('head')[0].appendChild(a);
            this.load(
              '//dev.visualwebsiteoptimizer.com/j.php?a=' +
              account_id +
              '&u=' +
              encodeURIComponent(d.URL) +
              '&r=' +
              Math.random(),
            );
            return settings_timer;
          },
        };
      })();
      _vwo_code.init();`}
    </script>
  </Helmet>
);
