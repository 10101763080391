import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { authInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const authSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: authInitialState,
  reducers,
  extraReducers,
});

export const { clearAuth, updateCustomerLegals } = authSlice.actions;
export default authSlice.reducer;
