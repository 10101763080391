import { useCSS } from '@insuma/mpp-ui';
import { Loading } from '../../loading';

import './suspense-app-loading.scss';

export const SuspenseAppLoading = () => {
  const css = useCSS('suspense-app-loading');

  return (
    <div className={css()}>
      <Loading />
    </div>
  );
};
