import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loadAddressesAction } from 'core/store/addresses';
import { addressesSelector } from 'core/store/addresses/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import { useSelectedAddress } from '../address/use-selected-address.hooks';

const useInitializeAddresses = () => {
  const dispatch = useAppDispatch();
  const isLogged = useIsAuthenticated();
  const { addresses, loading, error } = useAppSelector(addressesSelector);
  const { selectedAddress, setSelectedAddress: updateAddress } = useSelectedAddress();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isLogged) {
      dispatch(loadAddressesAction());
    }
  }, [isLogged, dispatch, inProgress]);

  useEffect(() => {
    if (!loading) {
      const isNotSelectedAddress = Object.keys(selectedAddress || {}).length === 0;
      const isIncludeInAddresses = addresses.some(ad => ad.customerId === selectedAddress?.customerId);

      if (isNotSelectedAddress || !isIncludeInAddresses) {
        updateAddress(addresses[0]);
      }
    }
  }, [loading, addresses, selectedAddress, updateAddress]);

  return {
    loading: loading && selectedAddress,
    addresses,
    error,
    isLogged,
  };
};

export default useInitializeAddresses;
