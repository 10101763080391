import Cookiebot from 'react-cookiebot/lib/CookieBot';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarGlobal, ThemeProvider } from '@insuma/mpp-ui';
import { PersistGate } from 'redux-persist/integration/react';
import { CLARITY_PROJECT_ID, COOKIEBOT_ID, META_PIXEL_CODE, VWO_ACCOUNT_ID } from 'core/constants/general.constants';
import { insumaTheme } from 'core/constants/themes.constants';
import { MonitoringProvider } from 'core/contexts/monitoring.context';
import { ScreenProvider } from 'core/contexts/screen.context';
import { Clarity } from 'shared/components/analytics/clarity';
import { MetaPixel } from 'shared/components/analytics/meta-pixel';
import { VWO } from 'shared/components/analytics/vwo';
import monitoringInstance from 'shared/utils/monitoring-provider.utils';
import store, { persistor } from '../core/store';
import msalPublicClientApplication, { CustomNavigationClient } from '../shared/utils/msal.utils';
import { RollbackInternalError } from './errors/rollback-internal-error';
import { MainRoutes } from './main.routes';

const AppContent = () => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalPublicClientApplication.setNavigationClient(navigationClient);

  return (
    <MonitoringProvider provider={monitoringInstance}>
      <MsalProvider instance={msalPublicClientApplication}>
        <ThemeProvider theme={insumaTheme}>
          <SnackbarGlobal />
          {/* <WhatsappButton /> */}
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {CLARITY_PROJECT_ID && <Clarity projectId={CLARITY_PROJECT_ID} />}
              {VWO_ACCOUNT_ID && <VWO accountId={VWO_ACCOUNT_ID} />}
              {META_PIXEL_CODE && <MetaPixel code={META_PIXEL_CODE} />}
              {COOKIEBOT_ID && <Cookiebot domainGroupId={COOKIEBOT_ID} />}
              <ScreenProvider>
                <MainRoutes />
              </ScreenProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
      </MsalProvider>
    </MonitoringProvider>
  );
};

const App = () => (
  <ErrorBoundary FallbackComponent={RollbackInternalError}>
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  </ErrorBoundary>
);

export default App;
