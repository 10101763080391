import { ISimplifiedProduct } from '@insuma/mpp-ui';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { IPromoCart } from 'core/model/interfaces/discount.interface';
import { ICartProduct, IProduct, IUnitMeasure } from 'core/model/interfaces/product.interface';
import { canPurchaseProduct } from './credit.utils';
import { cloneDeepSimple } from './objectUtils';

interface ICardProductData {
  product: ISimplifiedProduct;
  unitMeasure: IUnitMeasure;
}

interface IProductToCardProductDataParams {
  product: IProduct;
  cartProduct?: ICartProduct;
  customerSource: ECustomerSource;
  canPurchaseDex: boolean;
  canPurchaseApudex: boolean;
}

export const getProductFinalPrice = (product: IProduct) => product.price;
export const getProductCommercialName = (commercialName: string | undefined | null, fallbackName: string) => {
  if (commercialName !== '' && commercialName !== null && commercialName !== undefined) return commercialName;
  return fallbackName;
};
export const productHasDiscounts = (product: IProduct) => product.hasDiscount;
export const hasReducedPrice = (promos: Array<IPromoCart>) => promos.filter(promo => promo.value !== null).length > 0;

export const getProductTotalDiscountValue = (discounts: Array<IPromoCart> = []) =>
  discounts.reduce((current, promo) => {
    const value = promo.value || 0;
    return current + value;
  }, 0);

export const mapProductToCardProductData = ({
  product,
  cartProduct,
  customerSource,
  canPurchaseDex,
  canPurchaseApudex,
}: IProductToCardProductDataParams): ICardProductData => {
  const finalProduct = cloneDeepSimple(product);

  if (cartProduct) {
    finalProduct.stock = cartProduct.stock;
    finalProduct.presentation = cartProduct.presentation;
    finalProduct.maximumSaleQuantity = cartProduct?.maximumSaleQuantity;
    finalProduct.minimumSaleQuantity = cartProduct?.minimumSaleQuantity;
    finalProduct.price = cartProduct?.price;
    finalProduct.originalPrice = cartProduct?.originalPrice;
  }

  const {
    currency,
    image,
    originalPrice,
    stock,
    presentation,
    maximumSaleQuantity,
    minimumSaleQuantity,
    selectedUnitMeasure,
    commercialName,
  } = finalProduct;

  const isActiveProduct = canPurchaseProduct({
    productSource: product.sourceId,
    customerSource,
    canPurchaseDex,
    canPurchaseApudex,
  });

  return {
    product: {
      active: isActiveProduct,
      currencySymbol: currency?.symbol,
      finalPrice: getProductFinalPrice(finalProduct),
      hasDiscount: productHasDiscounts(finalProduct),
      image,
      regularPrice: originalPrice,
      stock,
      subtitle: presentation,
      title: commercialName,
      maximumSaleQuantity,
      minimumSaleQuantity,
    },
    unitMeasure: cartProduct?.selectedUnitMeasure ?? selectedUnitMeasure,
  };
};
