import { ICustomer } from 'core/model/interfaces/customer.interface';
import { IAuthState } from './types';

export const SLICE_NAMESPACE = 'auth';

// TODO: Considerar el caso cuando el servicio de getProfileUser() falle
export const authInitialState: IAuthState = {
  customer: null as any as ICustomer,
  loading: true,
  error: null,
};
