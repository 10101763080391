import { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import useInitializeAddresses from 'core/hooks/app/use-initialize-addresses';
import { MenuLayout } from 'shared/components/main-components/menu-layout';
import { ProtectedRoutes } from 'shared/components/protected-routes';
import { SuspensePage } from 'shared/components/suspense/supense-page';
import { SuspenseAppLoading } from 'shared/components/suspense/suspense-app-loading';
import { IndexPage } from './index-page';
import {
  LazyCategoryCatalog,
  LazyCheckout,
  LazyHome,
  LazyLogout,
  LazyMainLayout,
  LazyMyOrders,
  LazyMySession,
  LazyNotFound,
  LazyProductDetail,
  LazySearchResults,
  LazyStaticPages,
  LazySubcategoryCatalog,
  LazySuggestedProductsPages,
} from './screens.lazy';

export const MainRoutes = () => {
  const { loading, isLogged } = useInitializeAddresses();

  return (
    <Suspense fallback={<SuspenseAppLoading />}>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        {!isLogged && (
          <Route
            path={`${Path.STATIC_PAGES}/:slug`}
            element={
              <LazyMainLayout>
                <LazyStaticPages />
              </LazyMainLayout>
            }
          />
        )}
        <Route
          element={
            <ProtectedRoutes>
              {loading ? (
                <SuspenseAppLoading />
              ) : (
                <LazyMainLayout>
                  <SuspensePage>
                    <Outlet />
                  </SuspensePage>
                </LazyMainLayout>
              )}
            </ProtectedRoutes>
          }
        >
          <Route path={Path.LOGOUT} element={<LazyLogout />} />
          <Route path={Path.MY_ORDERS} element={<LazyMyOrders />} />
          <Route path={Path.SEARCH} element={<LazySearchResults />} />
          <Route path={`${Path.PRODUCT}/:id`} element={<LazyProductDetail />} />
          <Route path={Path.MY_SESSION} element={<LazyMySession />} />
          <Route path={`${Path.CHECKOUT}/*`} element={<LazyCheckout />} />
          <Route element={<MenuLayout />}>
            <Route path={Path.HOME} element={<LazyHome />} />
            <Route path={Path.SUGGESTED_PRODUCTS_PAGE} element={<LazySuggestedProductsPages />} />
            <Route path={`${Path.CATALOG}/:categorySlug`} element={<LazyCategoryCatalog />} />
            <Route path={`${Path.CATALOG}/:categorySlug/:subcategorySlug`} element={<LazySubcategoryCatalog />} />
            <Route path={`${Path.STATIC_PAGES}/:slug`} element={<LazyStaticPages />} />
          </Route>
          <Route path="*" element={<LazyNotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
