import axios, { GenericAbortSignal } from 'axios';
import axiosRetry from 'axios-retry';
import { EHTTPErrorCode } from 'core/constants/error-http.constants';
import { NUMBER_OF_GET_CART_API_RETRIES } from 'core/constants/shopping-cart.constants';
import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { IGetShoppingCartDTO, IGetShoppingCartParams, IPutShoppingCartRequestParams } from './shopping-cart.dto';

export const baseUrl = '/gtm-order-management/v1';
export const shoppingCartData = {
  getShoppingCart: async (
    customerId: number,
    params?: IGetShoppingCartParams,
    shouldRetry?: boolean,
  ): Promise<IGetShoppingCartDTO> => {
    // TODO: revisar por qué axios-retry está afectando a todos los demás servicios
    axiosRetry(https, { retries: 0 });
    const { data } = await https.get<IServiceSuccess<IGetShoppingCartDTO>>(`${baseUrl}/cart`, {
      params,
      'axios-retry': {
        retries: shouldRetry ? NUMBER_OF_GET_CART_API_RETRIES : 0,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: error => axios.isAxiosError(error) && error.response?.status === EHTTPErrorCode.SERVER_ERROR,
      },
      headers: { 'customer-id': customerId },
    });
    return data;
  },

  putShoppingCart: async (
    params: IPutShoppingCartRequestParams,
    customerId: number,
    signal?: GenericAbortSignal,
  ): Promise<IServiceSuccess> =>
    https.put<IServiceSuccess>(`${baseUrl}/cart/items`, params, { signal, headers: { 'customer-id': customerId } }),

  deleteProductShoppingCart: async (productId: string): Promise<IServiceSuccess> =>
    https.delete<IServiceSuccess>(`${baseUrl}/cart/items/${productId}`),
};
