import ReactDOM from 'react-dom';
import msalPublicClientApplication from 'shared/utils/msal.utils';
import { saveTokenToLocalStorage } from 'shared/utils/token.utils';
import App from './screens/app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';

msalPublicClientApplication.initialize().then(() => {
  msalPublicClientApplication
    .handleRedirectPromise()
    .then(res => {
      if (res) saveTokenToLocalStorage(res.accessToken);
    })
    .finally(() => {
      ReactDOM.render(<App />, document.getElementById('root'));
    });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
reportWebVitals();
